import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-scroll";
import "./footer.scss";

import Logo from '../../assets/footer/pluto-security-logo-w.png';
import Arrow from '../../assets/footer/arrow.svg';

const partnerBox = () => (
  <div className="footer">
    <div className="wrapper">
      <Row >
        <Col xs={12} sm={4} md={4} >
          <div>
            <img src={Logo} alt="logo" style={{
              marginLeft: '23px',
              maxWidth:"60%"
            }}/>
            <p style={{marginTop:"7px"}}>
            The Pluto Security, a leading provider of job opportunities across the Canada. Our mission is to connect job seekers with rewarding employment opportunities in a variety of sectors, including security services, Staffing/recruiting services, events, and many others.
            <br/>
            </p>
          </div>
        </Col>
        <Col xs={12} sm={4} md={4} style={{
          marginTop: '15px',
        }}>
          <div className="text-center">
            <h3 className="text-center" style={{color:"white",paddingLeft:"20px"}}>Our Address</h3>
            <p style={{
              marginTop: '10px',
            }}>
              <span style={{color:"#994900",
              fontWeight:"500",
              fontSize:"1.5rem"
            }}>
              PLUTO SECURITY
                </span><br/>
                52 Church Street <br/>
                Toronto, ON. Canada <br/>
              <i className="bi bi-envelope" /> {' '}{' '} <a href="mailto:info@plutosecurity.ca" className="whiteColor" style={{ textDecoration: "none"}}>info@plutosecurity.ca</a><br/>
                    <i className="bi bi-telephone" /> {' '}{' '} 
                    <a href="tel:+17058059942" className="whiteColor" style={{ textDecoration: "none"}}>+1 (705) 805-9942 </a>
                    <br/>
            </p>
          </div>
        </Col>
        <Col xs={12} sm={4} md={4} style={{
          marginTop: '15px',
        }}>
          <div className="text-center">
            <h3 style={{color:"white"}}>Quick Links</h3>
            <ul style={{marginTop:"10px"}}>
            <li>
            <Link  to="hero" spy={true} smooth={true} offset={-70} duration={500}>
              Home
            </Link>
          </li> 
          <li>
            <Link  to="about" spy={true} smooth={true} offset={-70} duration={500}>
              About Us 
            </Link>
          </li>
        <li>
            <Link  to="blog" spy={true} smooth={true} offset={-70} duration={500}>
              Services
            </Link>
          </li> 
          <li>
            <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}>
              Contact Us 
            </Link>
          </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} md={6}>
          <div className="footer-box text-center">
            <p>© 2024 - <span  style={{
              color: '#994900',
            }}>Pluto Security</span> All Right Reserved</p>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <Link to="hero" spy={true} smooth={true} offset={0} duration={500}>
            <div className="footer-box back-to-top text-center">
              <p>BACK TO TOP</p>
              <img src={Arrow} alt="arrow" />
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  </div>
);
export default partnerBox;
