import React from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
//Assets
import HeroImage from '../../assets/hero/hero-img3.jpg';
//Components
import Button from '../ui-components/button/button';

const hero = () => (
  <div className="hero" id="hero">
    <div className="wrapper">
      <Row style={{
        flexDirection: "row-reverse",
        alignItems: "center",
        alignContent: "center",
        marginTop: "2rem",
      }}>
        <Col md={12} lg={7}>
          <div className="hero-info">
            <h1 className="weight800 font50">
            Safeguarding Your World with  {' '}
            <span style={{color:"#994900",
              fontFamily:"Open Sans, sans-serif"
          
          }}>
               PLUTO SECURITY
            </span> 

            </h1>
            <p className="font14">
            The Pluto Security, a leading provider of job opportunities across the Canada. Our mission is to connect job seekers with rewarding employment opportunities in a variety of security services.
            </p>
            <Button label="SEND MESSAGE" target={"contact"}/>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default hero;
