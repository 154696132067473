import React from "react";
import { useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import "./contact-mini.scss";
import * as emailjs from "emailjs-com";

function Contactmini(props) {
    let _service = props.service + " Service";

    const [formState, setFormState] = useState({});
    const changeHandler = (e) => {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    };
    const submitHandler = (e) => {
      e.preventDefault();
      var template_params = {
        name: formState.name,
        email: formState.email,
        phone: formState.phone,
        message: formState.message,
        service: props.service,
      };
      //console.log(template_params);
      let API_KEY = "bfuYdsfYHpEqK4Rkb";
      let TEMPLATE_ID = "template_l2p983v";
      emailjs.send("service_bq5l98m", TEMPLATE_ID, template_params, API_KEY).then(
        function (response) {
          if (response.status === 200) {
            // self.showSuccessModal();
            alert("Your message has been sent successfully. We will contact you soon.");
            window.location.reload();
          } else {
            alert("Something went wrong. Please try again.");
          }
        },
        function (error) {
          alert("Something went wrong. Please try again.");
        }
    );
    };

    return (
      <div id="contact-mini">
        <div>
          <p className="font14 weight800">
              Please fill out the form below and we will get back to you as soon as possible.
          </p>
          <Row style={{
              paddingTop: "20px",
          }}>
              <form id="contact-form">
                <input type="text" placeholder="Name" required name="name" onChange={changeHandler}/>
                <input type="email" placeholder="Email" required name="email" onChange={changeHandler} />
                <input type="text" placeholder="Phone" required name="phone" onChange={changeHandler}/>
           
                <input type="text" placeholder="Service" required name="service" value={_service} disabled/>
                <textarea
                  rows="6"
                  cols="50"
                  placeholder="Message..."
                  required
                  name="message"
                  onChange={changeHandler}
                ></textarea>
                <div className="small__button1 text-center">
                  <button aria-label="send message" type="submit" value="Submit" onClick={submitHandler}>
                    Submit
                  </button>
                </div>
              </form>
          </Row>
        </div>
      </div>
    );
};

export default Contactmini;
