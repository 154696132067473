import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./about.scss";
// Components
import TeamBox from './teamBox';
import TeamInfo from "./teamInfo";
import Title from "../ui-components/title/title";
// Assets
import About from "../../assets/about/about-2.jpeg";

const about = () => (
  <div id="about">
    <div className="wrapper">
      <h3 className="font40 weight700 text-center">
          About Us
      </h3>
      <p className="font30 weight700 text-center"
        style={{
          color: "#994900",
          marginTop:"20px",
          fontFamily:"Open Sans, sans-serif"
        }}
      >
          PLUTO SECURITY
      </p>
      <Row style={{
        marginTop: '1.2rem',
      }}>
        <Col md={12} lg={6}>
          <p className='para'>
            <br/>
          At Pluto Security, we are a leading Security Services company dedicated to providing Top-notch protection and peace of mind. With our team of Highly trained professionals, cutting-edge technology, and customized solutions, we strive to exceed our clients' expectations by delivering comprehensive security services for construction sites, events, executives, residential and commercial properties, as well as offering risk assessment, alarm response, and facility management. Our unwavering commitment to excellence and our focus on ensuring safety and security make us the trusted choice for all your security needs.
          </p>
        </Col>
        <Col md={12} lg={6}>
          <img src={About} style={{
            borderRadius: '0',
            marginTop: '2rem',

          }}/>
          
        </Col>
      </Row>
    </div>
  </div>
);

export default about;
