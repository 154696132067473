import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./contact.scss";
import * as emailjs from "emailjs-com";
import Title from "../ui-components/title/title";
import contactSocial from "./contactInfo/contactSocial";


import ContactBackground from '../../assets/contact/contact.jpg';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone:"",
      message: "",
      sending: false,
      successModal: false,
      errorModal: false,
    };
  }

  inputHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (e) => {
    var self = this;
    this.setState({ sending: true });
    e.preventDefault();

    var template_params = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
    };

    // YOUR EMAIL.JS API KEY 
    let API_KEY = "bfuYdsfYHpEqK4Rkb";

    // YOUR EMAIL.JS TEMPLATE ID
    let TEMPLATE_ID = "template_b6u0ckz";


    // console.log(template_params);
    // console.log("sending email");

    emailjs.send("service_bq5l98m", TEMPLATE_ID, template_params, API_KEY).then(
      function (response) {
        if (response.status === 200) {
          // self.showSuccessModal();
          alert("Your message has been sent successfully. We will contact you soon.");
          window.location.reload();
        } else {
          alert("Something went wrong. Please try again.");
        }
      },
      function (error) {
        alert("Something went wrong. Please try again.");
      }
    );
  };
  resetForm() {
    this.setState({ name: "", email: "",phone:"", message: "" });
  }
  resetForm = () => {
    this.setState({ name: "", email: "",phone:"", message: "" });
  };

  render() {
    let submitButtonRender = (
      <div className="small__button">
        <button aria-label="send message" type="submit" value="Send Message">
          Send Message
        </button>
      </div>
    );
    return (
      <div id="contact">
        {/* {modalRender} */}
        <div className="wrapper">
          <h2 className="weight800 font50 text-center">Contact Us</h2>
          <p className="font12 text-center">
              Feel free to contact us for any query, feedback or suggestion. We will be happy to assist you as soon as possible.
          </p>
          <br/>
          {/* <p className="font14">
            <a href="https://www.linkedin.com/company/v-connect-security/about/" style={{
              color:"#1e1e1e"
            }}>
            <i class="bi bi-linkedin"></i>{' '}linkedin
            </a>{' '}
            <a href="https://instagram.com/v_connect_security?igshid=OGQ5ZDc2ODk2ZA=="style={{
              color:"#1e1e1e",
              marginLeft:"10px"
            }}>
              <i class="bi bi-instagram"></i>{' '}Instagram
            </a>
          </p> */}
          <Row className="padding20">
          <Col md={12} lg={6} >
              <form id="contact-form" onSubmit={this.handleSubmit}>
                <h4 className="font30 weight800 padding30">Send Us Message</h4>
                <input type="text" placeholder="Name" required name="name" value={this.state.name} onChange={this.inputHandler} />
                <input type="email" placeholder="Email" required name="email" value={this.state.email} onChange={this.inputHandler} />
                <input type="text" placeholder="Phone" required name="phone" value={this.state.phone} onChange={this.inputHandler} />
                <textarea
                  rows="6"
                  cols="50"
                  placeholder="Message..."
                  required
                  name="message"
                  value={this.state.message}
                  onChange={this.inputHandler}
                ></textarea>
                {submitButtonRender}
              </form>
            </Col>
          <Col md={12} lg={6}>
              <div className="flex-center">
                <img src={ContactBackground} alt="contact background" className="c-img"
                  style={{
                    paadingTop: '4rem',
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
};

export default Contact;
