import React from "react";
import Swiper from "react-id-swiper";
import { Row, Col } from "react-flexbox-grid";
import { useState } from "react";
// SCSS
import "./blog.scss";
import "swiper/css/swiper.css";
// Assets
import Eventsecurity from "../../assets/blog/event-security.png";
import Constructionsecurity from "../../assets/blog/construction-security.jpg";
import Alarmresponse from "../../assets/blog/alarm-response.jpg";
import Fireprotection from "../../assets/blog/fire-protection.jpg";
import Residentialsecurity from "../../assets/blog/residential-security.jpg";
import Commercialsecurity from "../../assets/blog/commercial-security.jpg";
import Mobilesecurity from "../../assets/blog/mobile-security.jpeg";
import onsitesecurity from "../../assets/blog/onsite-security.jpg";
import uniformedsecurity from "../../assets/blog/uniformed-security.jpg";
import lossprevention from "../../assets/blog/loss-prevention.jpg";
// Components
import Title from "../ui-components/title/title";
import BlogBox from "./blogBox";

function Blog() {
    return (
      <div className="blog" id="blog">
        <div className="wrapper">
          <h3 className="font40 weight700 text-center">
              Our Services
          </h3>
          <p className="font12 text-center">
          These are the services we provide to our clients. We are always open to new ideas and suggestions. If you have any, please feel free to contact us. 
          </p>
          <div className="padding30">
            <Row>
                <Col md={6} lg={6}>
                  <BlogBox
                      image={onsitesecurity}
                      id="7"
                      title="On site Security"
                      description="The On-Site Security Guards are responsible to keep the property secure and prevent any kind of damage at the site."
                  />
                </Col>
                <Col md={6} lg={6}>
                  <BlogBox
                      image={Fireprotection}
                      id="8"
                      title="Life and Fire Protection"
                      description="Pluto Security Officers will diligently patrol your site and remain present until your specific security needs are met, ensuring  protection and peace of mind."
                  />
                </Col>
            </Row>
            <Row>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Eventsecurity}
                    id="1"
                    title="Event Security"
                    description="Event security guards are responsible for the security and safety of any event. Security Officers will ensure that the guest dont distrupt the event in any way. It also provide security during the event itself, and also responsible by looking for any signs of crime, identifying trespassers, and reporting suspicious incidents."
                />
              </Col>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Constructionsecurity}
                    id="2"
                    title="Construction Security"
                    description="Construction security guards are responsible to monitor the site for any signs of danger, and they can help to respond to any emergencies that may occur. They provide security services to construction sites, with fully equipped security guards and construction site tools."
                />
              </Col>
            </Row>
            <Row>
            <Col md={6} lg={6}>
              <BlogBox
                  image={lossprevention}
                  id="3"
                  title="Loss Prevention/Contractors Watch"
                  description="We provide security services 24hours a day, 7days a week with interior and exterior patrols of the site to prevent any possible theft. Our dedicated team remains vigilant, safeguarding your premises day and night."
              />
            </Col>
            <Col md={6} lg={6}>
              <BlogBox
                  image={Alarmresponse}
                  id="4"
                  title="Alarm Response"
                  description="The main key we are responsible on alarms to investigate as per the clients request within quick response time and report immediately if any suspicious activity at site."
              />
            </Col>
            </Row>  
            <Row>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Mobilesecurity}
                    id="9"
                    title="Mobile Patrols"
                    description="We provide regular check patrols at your property to keep it safe and secure and report immediately."
                />
              </Col>
              <Col md={6} lg={6}>
                <BlogBox
                    image={uniformedsecurity}
                    id="10"
                    title="Uniformed Protection"
                    description="Pluto Security guard stands out to your customers and provides a clear point of contact for any concerns or questions if they have."
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Residentialsecurity}
                    id="5"
                    title="Residential and Retail Security"
                    description="They are responsible to provide consistently reliable service in a timely and professional manner by supplying all the equipment and skills necessary to handle just about every type of job our clients have for our officers. Our officers go above and beyond to exceed client expectations. We prioritize excellence, ensuring reliable and exceptional service every time."
                />
              </Col>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Commercialsecurity}
                    id="6"
                    title="Commercial and Industrial Security"
                    description="Our security guards are at the core of everything we do. We invest time in an intensive selection process, which includes in-depth background checks followed by extensive training in first aid, fire prevention, customer service and security-specific skills. All to ensure your facilities, assets, and people have the best security possible. "
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }

export default Blog;
